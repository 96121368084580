import axios from "axios";
import router from "./router";
import { getIdToken } from "firebase/auth";
import { auth } from "./plugins/firebase";
import { eventBus } from "./eventBus";

export const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

let refreshRequest = null;
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response) {
      const { status, data } = error.response;
      if (status === 403) {
        router.push({
          name: "Logout",
          query: { message: "forbidden" },
        });
      } else if (
        status === 401 &&
        data?.i18nKey !== "WRONG_PASSWORD" &&
        !error.config._retryRequest
      ) {
        if (!refreshRequest) {
          refreshRequest = getIdToken(auth.currentUser);
        }
        try {
          const token = await refreshRequest;
          refreshRequest = null;
          instance.defaults.headers.Authorization = `Bearer ${token}`;
          const reTryConfig = { ...error.config, _retryRequest: true };
          reTryConfig.headers.Authorization = `Bearer ${token}`;
          return instance(reTryConfig);
        } catch (error) {
          if (router.history.current.name !== "Logout") {
            router.push({ name: "Logout" });
          }
        }
      } else if (status === 401) {
        if (router.history.current.name !== "Logout") {
          router.push({ name: "Logout" });
        }
      } else {
        eventBus.$emit(
          "showError",
          error?.response?.data?.message || error.message
        );
      }
    }

    throw error;
  }
);

export const setToken = (token) => {
  instance.defaults.headers.Authorization = `Bearer ${token}`;
};

export const removeToken = () => {
  delete instance.defaults.headers.Authorization;
};

export const API = {
  async getCurrentUser() {
    const { data } = await instance.get("/account");
    return data;
  },
  async getUsers(params) {
    const { data } = await instance.get("admin/users", { params });
    return data;
  },
  async getUser(params) {
    const { userId } = params;
    const { data } = await instance.get(`/admin/users/${userId}`);
    return data;
  },
  async getForests(params) {
    const { data } = await instance.get("admin/forests", { params });
    return data;
  },
  async getForest(params) {
    const { forestId } = params;
    const { data } = await instance.get(`admin/forests/${forestId}`);
    return data;
  },
  async getForestTrees(payload) {
    const { forestId, params } = payload;
    const { data } = await instance.get(`admin/forests/${forestId}/trees`, {
      params,
    });
    return data;
  },
  async updateForestPlatform(data) {
    const { forestPlatformData, forestId, platformType } = data;
    return instance.patch(
      `admin/forests/${forestId}/platforms/${platformType}`,
      forestPlatformData
    );
  },
  async getVisitors(params) {
    const { data } = await instance.get(`admin/visitors`, {
      params,
    });
    return data;
  },
  async getVisitorNumber(payload) {
    const { params } = payload;
    const { data } = await instance.get(`admin/visitors/number`, { params });
    return data;
  },
  async removeTree(payload) {
    const { forestId, treeId } = payload;
    const { data } = await instance.delete(
      `/admin/forests/${forestId}/trees/${treeId}`
    );
    return data;
  },
  async updateUserEmailVerify(data) {
    const { emailVerified, id } = data;
    return instance.patch(`admin/users/${id}/verify`, {
      emailVerified,
    });
  },
  async updateUserEmailEdit(data) {
    const { email, id } = data;
    return instance.patch(`admin/users/${id}/email`, {
      email,
    });
  },
  async removeUser(data) {
    const { id, type, comment, feedback } = data;
    return instance.post(`admin/users/${id}/${type}`, {
      comment,
      feedback,
    });
  },
  async updateInvoiceAddress(data) {
    const { id, address } = data;
    return instance.patch(`admin/invoices/${id}/address`, address);
  },
  async getBillingInfo(payload) {
    const { companyId } = payload;
    const { data } = await instance.get(`admin/companies/${companyId}/billing`);
    return data;
  },
  async getInvoices(params) {
    const { data } = await instance.get("/admin/invoices", { params });
    return data;
  },
  async getInvoice(invoiceId) {
    const { data } = await instance.get(`/admin/invoices/${invoiceId}`);
    return data;
  },
  async previewCreditNote(payload) {
    const { stripeInvoiceId, data: payloadData } = payload;
    const { data } = await instance.post(
      `/admin/invoices/${stripeInvoiceId}/credit-notes/preview`,
      payloadData
    );
    return data;
  },
  async issueCreditNote(payload) {
    const { stripeInvoiceId, data: payloadData } = payload;
    const { data } = await instance.post(
      `/admin/invoices/${stripeInvoiceId}/credit-notes`,
      payloadData
    );
    return data;
  },
  async getInvoiceCreditNotePdf(payload) {
    const { stripeInvoiceId, stripeCreditNoteId } = payload;
    const { data } = await instance.get(
      `/admin/invoices/${stripeInvoiceId}/credit-notes/${stripeCreditNoteId}/pdf`
    );
    return data;
  },
  async getInvoicePdf(stripeInvoiceId) {
    const { data } = await instance.get(
      `/admin/invoices/${stripeInvoiceId}/pdf`
    );
    return data;
  },
  async getInvoiceTrees(payload) {
    const { stripeInvoiceId, params } = payload;
    const { data } = await instance.get(
      `/admin/invoices/${stripeInvoiceId}/trees`,
      { params }
    );
    return data;
  },
  async getCustomToken(userId) {
    const { data } = await instance.post(`/admin/users/${userId}/authtoken`);
    return data;
  },
  async getUserWorkspace(payload) {
    const { userId } = payload;
    const { data } = await instance.get(`admin/users/${userId}/workspaces`);
    return data;
  },
  async getCompany(params) {
    const { companyId } = params;
    const { data } = await instance.get(`admin/companies/${companyId}`);
    return data;
  },
  async getCompanies(params) {
    const { data } = await instance.get("admin/companies", { params });
    return data;
  },
  async getEmails(params) {
    const { data } = await instance.get("/admin/emails", { params });
    return data;
  },
};
