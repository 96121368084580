import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Emails "),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.emails,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalAmount,"footer-props":{
      'items-per-page-options': _vm.$options.DEFAULT_ITEMS_PER_PAGE_OPTIONS,
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.recipientNumber",fn:function(ref){
    var item = ref.item;
return [(item.userId)?_c('router-link',{attrs:{"to":{ name: 'User', params: { id: item.userId } }}},[_vm._v(" "+_vm._s(item.recipientNumber)+" ")]):_c('span',[(item.ownedBy)?_c('router-link',{attrs:{"to":{ name: 'Company', params: { id: item.ownedBy.id } }}},[_vm._v(" "+_vm._s(item.recipientNumber)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.recipientNumber)+" ")])],1)]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)},[_c('span',[_c('ul',_vm._l((item.sgLogs),function(sgLog){return _c('li',{key:sgLog.sg_event_id},[_vm._v(" "+_vm._s(sgLog.event)+" - "+_vm._s(_vm.formatDate(sgLog.timestamp * 1000))+" ")])}),0)])])]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updatedAt))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }