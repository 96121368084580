<template>
  <div v-if="!loading">
    <v-breadcrumbs divider=">" :items="breadcrumbs" exact></v-breadcrumbs>
    <user-profile
      class="mb-10"
      :user="user"
      :workspaces="workspaces"
      :selectedWorkspace="selectedWorkspace"
      v-model="selectedWorkspaceId"
      @updateEmail="updateEmail"
      @updateEmailVerification="updateEmailVerification"
    />
    <user-emails class="mb-10" :id="user.id"></user-emails>
    <forest-table
      class="mb-10"
      :companyId="selectedCompanyId"
      user-profile
    ></forest-table>
    <company-billing-info
      class="mb-10"
      :id="selectedCompanyId"
    ></company-billing-info>
    <company-invoices class="mb-10" :id="selectedCompanyId"></company-invoices>
  </div>
</template>
<script>
import UserProfile from "../components/User/UserProfile.vue";
import UserEmails from "../components/User/UserEmails.vue";
import ForestTable from "../components/Forest/ForestTable.vue";
import CompanyBillingInfo from "../components/Company/CompanyBillingInfo.vue";
import CompanyInvoices from "../components/Company/CompanyInvoices.vue";
import { API } from "../api";

export default {
  name: "UserViews",
  components: {
    UserProfile,
    ForestTable,
    CompanyBillingInfo,
    CompanyInvoices,
    UserEmails,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      selectedWorkspaceId: null,
      workspaces: [],
      user: {},
    };
  },
  async created() {
    this.loading = true;
    try {
      const [user, workspaces] = await Promise.all([
        API.getUser({ userId: this.id }),
        API.getUserWorkspace({ userId: this.id }),
      ]);
      this.user = user;
      this.workspaces = workspaces;
      if (this.workspaces.length > 0) {
        this.selectedWorkspaceId = this.workspaces[0].id;
      }
      this.loading = false;
    } catch (error) {
      this.loading = false;
      return;
    }
  },
  computed: {
    selectedCompanyId() {
      return this.selectedWorkspace?.companyId.id;
    },
    selectedWorkspace() {
      return this.workspaces.find(
        (workspace) => this.selectedWorkspaceId === workspace.id
      );
    },

    breadcrumbs() {
      return [
        {
          text: "Users",
          to: { name: "Users" },
          exact: true,
        },
        {
          text: this.user?.email,
        },
      ];
    },
  },
  methods: {
    updateEmail(email) {
      this.user.email = email;
    },
    updateEmailVerification(value) {
      this.user.emailVerified = value;
    },
  },
};
</script>
